import React, {FunctionComponent} from "react";
import {Button, ButtonProps, CircularProgress} from "@material-ui/core";

export type LoadingButtonProps = {
    loading: boolean,
}

export const LoadingButton: FunctionComponent<LoadingButtonProps & ButtonProps> = (props) => {
    const {loading, ...rest} = props;
    return <Button
        {...rest} disabled={loading}>
        {loading && <CircularProgress size={16} style={{marginRight: '1em'}}/>}
        {props.children}
    </Button>;
};
