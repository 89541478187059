import {Redirect, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {GameInfo, isInGame, joinGame} from "../redux";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import styles from './JoinGame.module.scss';
import {Alert, AlertTitle} from "@material-ui/lab";
import {Box, Button} from "@material-ui/core";
import {Api} from "../api";
import {LoadingButton} from "../LoadingButton";
import {QrReader} from "@blackbox-vision/react-qr-reader";

export function JoinGame() {
    const inGame = useSelector(isInGame);
    const history = useHistory();
    const dispatch = useDispatch();
    const [joinCode, setJoinCode] = useState('');
    const [isJoining, setJoining] = useState(false);
    const [error, setError] = useState('');
    const [isScanning, setScanning] = useState(false);
    const [status, setStatus] = useState('Loading your camera...');

    if (inGame) {
        return <Redirect to={'/tasks'}/>;
    }

    const onJoinGame = async (joinCode: string) => {
        if (joinCode.trim() === '') return;

        setJoining(true);

        let configuration: GameInfo;
        try {
            configuration = await Api.getGameInfo(joinCode);
        } catch (e) {
            console.error('Error fetching game info:', e);
            setJoining(false);
            return setError(`Invalid game code entered.`);
        }

        dispatch(joinGame(configuration.game, configuration));

        history.push('/tasks');
    };

    const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        onJoinGame(joinCode);
        e.preventDefault();
    };

    const scanDone = (data?: string) => {
        if (!data) {
            return setStatus(`Scanning...`);
        }

        try {
            const url = new URL(data);
            const join = url.pathname.split(':')[0];
            const gameCode = url.pathname.split(':')[1];

            if (join !== 'join') {
                throw Error("invalid qr code");
            }

            setScanning(false);
            setJoinCode(gameCode);
            navigator.vibrate(150);
            onJoinGame(gameCode);
        } catch (e) {
            setStatus(`This is not a game QR code!`);
        }
    };

    const onError = (e: any) => {
        if (e.toString().includes('NotAllowedError')) {
            setStatus(`Please unblock the camera access to use code scanner!`);
        } else {
            setStatus(`Error: ${e}`);
        }
    };

    if (isScanning) {
        return <div>
            <QrReader
                onScan={scanDone}
                onLoad={() => setStatus('Scanning...')}
                onError={onError}
                facingMode={"environment"}
                resolution={256}
                showViewFinder={true}
                style={{width: '100%'}}
            />
            <Alert severity={"info"} style={{margin: '1em'}}>{status}</Alert>
            <Container>
                <Button fullWidth variant={"contained"} color={"secondary"} onClick={() => setScanning(false)}>Cancel
                    scan</Button>
            </Container>
        </div>;
    }

    return <Container className={styles.joinGame}>
        <Box style={{textAlign: 'center'}}>
            <img src={'./map.svg'} alt={"Look for QR codes"}/>
            <Typography gutterBottom variant={"h5"}>Enter the game code</Typography>
            <Typography gutterBottom>You were provided with your game code. Please enter the code here to join the
                game.</Typography>
        </Box>

        <form style={{margin: '1em 0', display: 'flex', flexDirection: 'column'}} onSubmit={(e) => formSubmit(e)}
              noValidate autoComplete={"off"}>
            <TextField id="standard-basic" variant="outlined" disabled={isJoining} label="Game code" value={joinCode}
                       onChange={(e) => setJoinCode(e.target.value)}
                       fullWidth/>
            <LoadingButton loading={isJoining} type="submit" variant={"contained"} color="secondary"
                           onClick={() => onJoinGame(joinCode)} fullWidth>
                Join Game
            </LoadingButton>
            <Button variant={"text"} style={{marginTop: '1em'}} onClick={() => setScanning(true)}>Scan QR code</Button>
        </form>


        {error && <Alert severity={"error"}>
            <AlertTitle>Cannot join game!</AlertTitle>
            {error}
        </Alert>}
    </Container>;
}
